.marker {
  &-cluster {
    &-small {
      background: none !important;
    }
  }
}

.leaflet {
  &-div {
    &-icon {
      background: none !important;
      border: none !important;
    }
  }

  &-control {
    &-scale {
      &-line {
        border: none !important;
        background-color: #fff6 !important;
        z-index: 99999 !important;
        padding: 6px 10px !important;
        width: fit-content !important;
        height: fit-content !important;
        grid-column: 6 / 8 !important;
        grid-row: 5 / 6 !important;
        justify-self: end !important;
        -ms-flex-item-align: end !important;
        align-self: end !important;
        border-radius: 4px !important;
        text-wrap: nowrap !important;
        margin: 0 !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 120% !important;
        color: #25292c !important;
        font-family: Roboto, sans-serif !important;
      }
    }
  }
}

.main {
  &__cluster {
    &_wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #4bc281;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      color: white;
      border: 4px solid white;
    }
  }

  &__point_drawer {
    &--temp_start_point {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #6473b9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      color: white;
      border: 4px solid white;
    }

    &--temp_end_point {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #6473b9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      color: white;
      border: 4px solid white;
    }

    &--temp_reject_point {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: red;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      color: white;
      border: 4px solid white;
    }

    &--polygon_point {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #6473b9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      color: white;
      border: 1px solid white;
    }

    &--direction_point {
      position: absolute;

      // width: fit-content;
      transform-origin: 50% 50%;

      // height: 100%;
      padding: 10px;
      white-space: pre;
      background-color: #6473b9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      color: white;
      border: 1px solid white;
    }

    &--reject_point {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius: 100px;
      color: white;
      border: 1px solid white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: url("../../assets/common/close.png");
    }

    &--success_point {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius: 100px;
      color: white;
      border: 1px solid white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: url("../../assets/common/success.png");
    }

    &--movement_point {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: gainsboro;
      border-radius: 100px;
      border: 1px solid gray;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      // background-image: url("../../assets/common/success.png");
    }
  }

  &__point_drawer_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #4bc281;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    color: white;
    border: 4px solid white;
  }

  &__marker {
    &_wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: url("../../assets/common/Marker.svg");
    }
  }
}
