:root {
  // DEFAULT
  --color-bg-hover: #f6f8fc;
  --color-bg-hover-black: #2e3545;
  --color-black: #000;
  --color-b-60: rgb(24 29 39 / 60%);
  --color-blue: #48acf5;
  --color-dark: #181d27;
  --color-white: #fff;
  --color-white-60: rgb(255 255 255 / 60%);
  --color-white-light: #eff1f5;
  --color-white-middle: #e8eaed;
  --color-white-dark: #c5c7c9;
  --color-gray-dark: #3d3d3f;
  --color-gray-medium: #666;
  --color-gray: #7c7e85;
  --color-gray-extra-light: #dadce0;
  --color-gray-light: #ccced5;
  --color-gray49: #7d7d7d;
  --color-gray-disabled: #cdd4db;
  --color-orange: #f80;
  --color-orange-light: #fff2e5;
  --color-yellow: #ffb931;
  --color-yellow-light: #ffc961;
  --color-yellow-circle: #ffb931;
  --color-red: #f54848;
  --color-red-hover: #f96b6b;
  --color-violet: #9747ff;
  --color-green: #6bda61;
  --color-empty: #c0c6d7;
  --color-stroke-light: #cfcffd;
  --color-placeholder: #dcdfe8;
  --color-gray-icon: #6c7c8c;
  --font-primary: "Muller", sans-serif;
  --gx-spacing-x-small: 0;
  --gx-toggle-size: 80px;
  --color-connect: var(--color-dark);
  --height-input: 116px;

  // * Animations time
  --transition-small: 0.3s;

  // CURRENT PROJECT
  --dl-color-default-black: rgb(37 40 44 / 100%);
  --dl-color-default-white: rgb(255 255 255 / 100%);
  --dl-color-default-stroke: rgb(215 215 224 / 100%);
  --dl-color-default-gray: rgb(131 146 160 / 100%);
  --dl-color-default-black30: rgb(37 40 44 / 30%);
  --dl-color-default-white60: rgb(255 255 255 / 60%);
  --dl-color-default-blue30: rgb(24 96 170 / 30%);
  --dl-color-default-orange: rgb(250 120 0 / 100%);
  --dl-color-default-viol: rgb(148 45 228 / 100%);
  --dl-color-appointments: rgb(179 75 16 / 100%);
  --dl-color-input-inputstroke: rgb(206 212 218 / 100%);
  --dl-color-input-placeholder: rgb(0 0 0 / 38%);
  --dl-color-blue-darkblue: rgb(24 96 170 / 100%);
  --dl-color-blue-blue: rgb(23 113 206 / 100%);
  --dl-color-blue-lightblue: rgb(46 140 237 / 100%);
  --dl-color-blue-hoverblue: rgb(205 229 255 / 100%);
  --dl-color-blue-superlightblue: rgb(218 236 255 / 100%);
  --dl-color-yellow-darkyellow: rgb(231 153 0 / 100%);
  --dl-color-yellow-yellow: rgb(247 167 10 / 100%);
  --dl-color-yellow-lightyellow: rgb(255 192 70 / 100%);
  --dl-color-red-darkred: rgb(226 6 19 / 100%);
  --dl-color-red-red: rgb(248 43 55 / 100%);
  --dl-color-red-lightred: rgb(255 98 107 / 100%);
  --dl-color-green-darkgreen: rgb(0 113 59 / 100%);
  --dl-color-green-green: rgb(0 160 73 / 100%);
  --dl-color-green-lightgreen: rgb(74 194 129 / 100%);
  --dl-color-bg-darkgray: rgb(230 230 243 / 100%);
  --dl-color-bg-gray: rgb(236 236 251 / 100%);
  --dl-color-bg-lightgray: rgb(246 246 252 / 100%);
  --dl-color-bg-superlightgray: rgb(249 249 255 / 100%);
  --dl-color-bg-white: rgb(255 255 255 / 100%);
  --dl-color-bg-transparent: rgb(255 255 255 / 0%);
  --dl-color-bg-scroll: rgb(37 41 44 / 30%);
}
